import { getItem } from '@helpers/array.helper';
import { KeyStringModel } from '@models/base.models';
import { HcpcsCode, HcpcsCodeAPI } from '@models/hcpcs';
import { getKeyStringData } from '@helpers/object.helper';
import { ProductConstants } from '@models/constants.models';
import { PRODUCT_CONFIG_GROUP_TYPE } from '@const/product-config';
import { getChoiceSettingsDataType } from '@helpers/product-settings.helper';
import { CHOICE_SHIPMENT_FREQUENCY_DROPDOWN } from '@const/product-shipment';
import { getChoiceShipmentQuantityView, getShipmentQuantityResultFromCalculatedData, getShipmentQuantityResultViewFromCalculatedData } from '@helpers/product-shipment.helper';

export class ProductConfigChoiceAPI {
  public id: string;
  public value: any;
  public value1: any;
  public name: string;
  public hcpcs: string;
  public units: number;
  public imageUrl: string;
  public dataType: number;
  public maxValue: number;
  public minValue: number;
  public isSingle: boolean;
  public entryType: number;
  public productId: number;
  public description: string;
  public hcpcsCodes: string[];
  public includeHcpcs: string;
  public isNameShown: boolean;
  public hcpcsCategory: string;
  public selectedAmount: number;
  public selectedUnitId: number;
  public allowChooseQty: boolean;
  public isBlankAllowed: boolean;
  public externalProductId: string;
  public shipmentFrequencyId: number;
  public isDescriptionShown: boolean;
  public configurationChoiceId: string;
  public selectedFrequencyInDays: number;
  public summaryShipmentQuantity: number;
  public groups: ProductConfigGroupAPI[];
  public isShipmentFrequencyShown: number;
  public summaryBillingUnitPerMonth: number;
  public childGroups: ProductConfigGroupAPI[];
  public shipmentExternalProductCodeSizeId: number;
  public isShipmentExternalProductCodeSizeShown: number;
  public conversionChartItems: ProductConversionChartItemAPI[];

  // PATIENT HEIGHT
  public minPatientHeight: number;
  public maxPatientHeight: number;
  public patientHeightRequired: boolean;

  // PATIENT WEIGHT
  public minPatientWeight: number;
  public maxPatientWeight: number;
  public patientWeightRequired: boolean;

  // SETTINGS
  public settingsGroupType: number;
  public settingsChoiceType: number;
  public settings: ProductConfigChoiceAPI[];
}

export class ProductConfigGroupsDataAPI {
  count: number;
  items: ProductConfigGroupAPI[];
}

export class ProductConfigGroupAPI {
  public id: string;
  public name: string;
  public type: number;
  public units: number;
  public hcpcs: string;
  public rootId: string;
  public imageUrl: string;
  public chartNote: string;
  public timestamp: string;
  public description: string;
  public isNameShown: boolean;
  public includeHcpcs: string;
  public hcpcsCategory: string;
  public isDescriptionShown: boolean;
  public choices: ProductConfigChoiceAPI[];
  public hcpcsCodesDescription: HcpcsCodeAPI[];
  public selectedChoices: ProductConfigChoiceAPI[];
}

export class ProductConfigChoice {
  public id: string;
  public value: any;
  public value1: any;
  public name: string;
  public hcpcs: string;
  public units: number;
  public imageUrl: string;
  public dataType: number;
  public maxValue: number;
  public minValue: number;
  public isSingle: boolean;
  public entryType: number;
  public productId: number;
  public description: string;
  public hcpcsCodes: string[];
  public includeHcpcs: string;
  public isNameShown: boolean;
  public hcpcsCategory: string;
  public allowChooseQty: boolean;
  public isBlankAllowed: boolean;
  public externalProductId: string;
  public isDescriptionShown: boolean;
  public groups: ProductConfigGroup[];
  public isShipmentFrequencyShown: number;
  public childGroups: ProductConfigGroup[];
  public summaryBillingUnitPerMonth: number;
  public isShipmentExternalProductCodeSizeShown: number;
  public conversionChartItems: ProductConversionChartItem[];

  public selectedAmount: number;
  public selectedUnitId: number;
  public selectedFrequencyInDays: number;

  public shipmentUnitId: number;
  public shipmentFrequencyId: number;
  public summaryShipmentQuantity: number;

  // PATIENT HEIGHT
  public minPatientHeight: number;
  public maxPatientHeight: number;
  public patientHeightRequired: boolean;

  // PATIENT WEIGHT
  public minPatientWeight: number;
  public maxPatientWeight: number;
  public patientWeightRequired: boolean;

  // SETTINGS
  public settingsGroupType: number;
  public settingsChoiceType: number;
  public settings: ProductConfigChoice[];

  // FE only
  public errorMsg: string;
  public valueChanged: boolean;
  public preparedValue: string;
  public skipCalculationError: boolean;
  public billingQuantityTitle?: string;
  public shipmentQuantityView?: number;
  public shipmentSelectedUnit?: number;
  public shipmentSelectedAmount?: number;
  public shipmentQuantityResult?: string;
  public shipmentSelectedFrequency?: number;
  public shipmentQuantityResultView?: string;

  public static getDataFromAPI(data: ProductConfigChoiceAPI, productConstants?: ProductConstants, choices?: ProductConfigChoiceAPI[]): ProductConfigChoice {
    const choice = getItem<ProductConfigChoiceAPI, string>(choices, 'configurationChoiceId', data.id);
    const conversionChartItems = data.conversionChartItems?.map(it => ProductConversionChartItem.getDataFromAPI(it, productConstants.productUnitsKeyValueData));

    return {
      id: data.id,
      name: data.name,
      value: data.value,
      hcpcs: data.hcpcs,
      value1: data.value1,
      conversionChartItems,
      imageUrl: data.imageUrl,
      maxValue: data.maxValue,
      minValue: data.minValue,
      isSingle: data.isSingle,
      entryType: data.entryType,
      productId: data.productId,
      units: data.units || null,
      hcpcsCodes: data.hcpcsCodes,
      description: data.description,
      isNameShown: data.isNameShown,
      includeHcpcs: data.includeHcpcs,
      hcpcsCategory: data.hcpcsCategory,
      allowChooseQty: data.allowChooseQty,
      isBlankAllowed: data.isBlankAllowed,
      dataType: getChoiceSettingsDataType(data),
      externalProductId: data.externalProductId,
      isDescriptionShown: data.isDescriptionShown,
      shipmentFrequencyId: data.shipmentFrequencyId,
      shipmentUnitId: data.shipmentExternalProductCodeSizeId,
      isShipmentFrequencyShown: data.isShipmentFrequencyShown,
      summaryBillingUnitPerMonth: data.summaryBillingUnitPerMonth,
      selectedAmount: choice?.selectedAmount || data.selectedAmount,
      selectedUnitId: choice?.selectedUnitId || data.selectedUnitId,
      isShipmentExternalProductCodeSizeShown: data.isShipmentExternalProductCodeSizeShown,
      summaryShipmentQuantity: choice?.summaryShipmentQuantity || data.summaryShipmentQuantity,
      selectedFrequencyInDays: choice?.selectedFrequencyInDays || data.selectedFrequencyInDays,
      groups: data.groups?.map(it => ProductConfigGroup.getDataFromAPI(it, productConstants, choices)),
      childGroups: data.childGroups?.map(it => ProductConfigGroup.getDataFromAPI(it, productConstants, choices)),

      // PATIENT HEIGHT
      minPatientHeight: data.minPatientHeight,
      maxPatientHeight: data.maxPatientHeight,
      patientHeightRequired: data.patientHeightRequired,

      // PATIENT WEIGHT
      minPatientWeight: data.minPatientWeight,
      maxPatientWeight: data.maxPatientWeight,
      patientWeightRequired: data.patientWeightRequired,

      // SETTINGS
      settingsGroupType: data.settingsGroupType,
      settingsChoiceType: data.settingsChoiceType,
      settings: data.settings?.map(it => ProductConfigChoice.getDataFromAPI(it, productConstants, choices)),

      // FE only
      errorMsg: null,
      valueChanged: false,
      billingQuantityTitle: null,
      shipmentQuantityView: null,
      skipCalculationError: false,
      shipmentSelectedAmount: null,
      shipmentSelectedFrequency: CHOICE_SHIPMENT_FREQUENCY_DROPDOWN[0].id,
      preparedValue: data.value?.replace(',', ' - '),
      shipmentSelectedUnit: conversionChartItems?.length ? conversionChartItems[0].unitId : null,
      shipmentQuantityResult: getShipmentQuantityResultFromCalculatedData(ProductsShipmentQuantity.getDataFromChoiceAPI(choice), productConstants),
      shipmentQuantityResultView: getShipmentQuantityResultViewFromCalculatedData(
        choice || data,
        ProductsShipmentQuantity.getDataFromChoiceAPI(choice),
        productConstants,
      ),
    };
  }

  public static getDataFromShipmentQuantity(
    choice: ProductConfigChoice, data: ProductsShipmentQuantity[], productConstants: ProductConstants,
  ): ProductConfigChoice {
    const shipmentQuantity = getItem<ProductsShipmentQuantity, string>(data, 'productId', choice.id);

    if (!shipmentQuantity) {
      return {
        ...choice,

        // FE only
        valueChanged: false,
        errorMsg: shipmentQuantity?.errorMsg || null,
        shipmentQuantityView: getChoiceShipmentQuantityView(choice, shipmentQuantity),
        skipCalculationError: shipmentQuantity?.errorMsg ? choice.skipCalculationError : false,
      };
    }

    return {
      ...choice,
      selectedAmount: shipmentQuantity.selectedAmount,
      selectedUnitId: shipmentQuantity.selectedUnitId,
      summaryShipmentQuantity: shipmentQuantity.shipmentQuantity,
      summaryBillingUnitPerMonth: shipmentQuantity.billingQuantity,
      selectedFrequencyInDays: shipmentQuantity.selectedFrequencyInDays,

      // FE only
      valueChanged: false,
      errorMsg: shipmentQuantity?.errorMsg || null,
      billingQuantityTitle: shipmentQuantity.billingQuantityTitle,
      shipmentQuantityView: getChoiceShipmentQuantityView(choice, shipmentQuantity),
      skipCalculationError: shipmentQuantity?.errorMsg ? choice.skipCalculationError : false,
      shipmentQuantityResult: getShipmentQuantityResultFromCalculatedData(shipmentQuantity, productConstants),
      shipmentQuantityResultView: getShipmentQuantityResultViewFromCalculatedData(choice, shipmentQuantity, productConstants),
    };
  }
}

export class ProductConfigGroup {
  public id: string;
  public type: number;
  public name: string;
  public units: number;
  public hcpcs: string;
  public rootId: string;
  public imageUrl: string;
  public timestamp: string;
  public chartNote: string;
  public description: string;
  public isNameShown: boolean;
  public includeHcpcs: string;
  public hcpcsCategory: string;
  public isDescriptionShown: boolean;
  public choices: ProductConfigChoice[];
  public hcpcsCodesDescription: HcpcsCode[];
  public selectedChoices: ProductConfigChoice[];

  // FE only
  public preparedHcpcsCodesDesc?: KeyStringModel<string>;

  public static getDataFromAPI(data: ProductConfigGroupAPI, productConstants?: ProductConstants, choices?: ProductConfigChoiceAPI[]): ProductConfigGroup {
    return {
      id: data.id,
      type: data.type,
      name: data.name,
      hcpcs: data.hcpcs,
      rootId: data.rootId,
      imageUrl: data.imageUrl,
      timestamp: data.timestamp,
      chartNote: data.chartNote,
      units: data.units || null,
      isNameShown: data.isNameShown,
      description: data.description,
      includeHcpcs: data.includeHcpcs,
      hcpcsCategory: data.hcpcsCategory,
      isDescriptionShown: data.isDescriptionShown,
      selectedChoices: ProductConfigGroup.getSelectedChoices(data, productConstants, choices),
      choices: data.choices?.map(it => ProductConfigChoice.getDataFromAPI(it, productConstants)),
      hcpcsCodesDescription: data.hcpcsCodesDescription?.map(it => HcpcsCode.getDataFromAPI(it)),

      // FE only
      preparedHcpcsCodesDesc: getKeyStringData<HcpcsCode, string>(data.hcpcsCodesDescription, 'code', 'shortDescription'),
    };
  }

  private static getSelectedChoices(
    data: ProductConfigGroupAPI, productConstants?: ProductConstants, choices?: ProductConfigChoiceAPI[],
  ): ProductConfigChoice[] {
    switch (data.type) {
      default:
        return data.selectedChoices?.map(it => ProductConfigChoice.getDataFromAPI(it, productConstants, choices));
      case PRODUCT_CONFIG_GROUP_TYPE.radioGroup:
        let selectedChoices = data.selectedChoices;

        if (data.choices?.length === 1) {
          selectedChoices = [...data.choices];
        }

        return selectedChoices?.map(it => ProductConfigChoice.getDataFromAPI(it, productConstants, choices));
    }
  }
}

export class ProductConversionChartItemAPI {
  unitId: number;
}

export class ProductConversionChartItem {
  name: string;
  unitId: number;

  public static getDataFromAPI(data: ProductConversionChartItemAPI, productConstUnits?: KeyStringModel<string>): ProductConversionChartItem {
    return {
      name: productConstUnits?.[data.unitId] || null,
      unitId: data.unitId,
    };
  }
}

export class ProductsShipmentQuantityRequest {
  productId: string;
  selectedAmount: number;
  selectedUnitId: number;
  selectedFrequencyInDays: number;

  public static getDataFromSelectedChoice(data: ProductConfigChoice): ProductsShipmentQuantityRequest {
    return {
      productId: data.id,
      selectedUnitId: data.shipmentSelectedUnit,
      selectedAmount: data.shipmentSelectedAmount,
      selectedFrequencyInDays: data.shipmentSelectedFrequency,
    };
  }
}

export class ProductsShipmentQuantityAPI {
  errorMsg: string;
  productId: string;
  shipmentUnitId: number;
  selectedAmount: number;
  selectedUnitId: number;
  billingQuantity: number;
  shipmentQuantity: number;
  shipmentFrequencyId: number;
  billingQuantityTitle: string;
  selectedFrequencyInDays: number;
}

export class ProductsShipmentQuantity {
  errorMsg: string;
  productId: string;
  shipmentUnitId: number;
  selectedAmount: number;
  selectedUnitId: number;
  billingQuantity: number;
  shipmentQuantity: number;
  shipmentFrequencyId: number;
  billingQuantityTitle: string;
  selectedFrequencyInDays: number;

  public static getDataFromAPI(data: ProductsShipmentQuantityAPI): ProductsShipmentQuantity {
    return {
      errorMsg: data.errorMsg,
      productId: data.productId,
      shipmentUnitId: data.shipmentUnitId,
      selectedAmount: data.selectedAmount,
      selectedUnitId: data.selectedUnitId,
      billingQuantity: data.billingQuantity,
      shipmentQuantity: data.shipmentQuantity,
      shipmentFrequencyId: data.shipmentFrequencyId,
      billingQuantityTitle: data.billingQuantityTitle,
      selectedFrequencyInDays: data.selectedFrequencyInDays,
    };
  }

  public static getDataFromChoiceAPI(data: ProductConfigChoiceAPI): ProductsShipmentQuantity {
    if (!data) return null;

    return {
      errorMsg: null,
      billingQuantityTitle: null,
      selectedAmount: data.selectedAmount,
      selectedUnitId: data.selectedUnitId,
      productId: data.configurationChoiceId,
      shipmentFrequencyId: data.shipmentFrequencyId,
      shipmentQuantity: data.summaryShipmentQuantity,
      billingQuantity: data.summaryBillingUnitPerMonth,
      selectedFrequencyInDays: data.selectedFrequencyInDays,
      shipmentUnitId: data.shipmentExternalProductCodeSizeId,
    };
  }
}
