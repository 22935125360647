import { ProductConstants } from '@models/constants.models';
import { CHOICE_SHIPMENT_QUANTITY_VIEW_TYPES } from '@const/product-shipment';
import { ProductConfigChoice, ProductConfigChoiceAPI, ProductsShipmentQuantity } from '@models/product';

export function getChoiceShipmentQuantityView(choice: ProductConfigChoice, productShipmentQuantity: ProductsShipmentQuantity): number {
  if (choice.allowChooseQty) {
    return CHOICE_SHIPMENT_QUANTITY_VIEW_TYPES.setQuantityFrequency;
  }

  if (productShipmentQuantity?.shipmentUnitId && productShipmentQuantity?.shipmentFrequencyId) {
    return CHOICE_SHIPMENT_QUANTITY_VIEW_TYPES.perDays;
  }

  return CHOICE_SHIPMENT_QUANTITY_VIEW_TYPES.unit;
}

export function getShipmentQuantityResultFromCalculatedData(shipQuant: ProductsShipmentQuantity, productConstants: ProductConstants): string {
  if (!shipQuant || !productConstants) return null;

  if (shipQuant.selectedFrequencyInDays) {
    if (!shipQuant.selectedAmount) return null;

    return shipQuant.selectedAmount + (shipQuant.selectedUnitId && shipQuant.selectedFrequencyInDays ? ' ' + productConstants.productUnitsKeyValueData[shipQuant.selectedUnitId] + ' / ' + getFrequencySuffix(shipQuant.selectedFrequencyInDays) : '');
  }

  if (!shipQuant.selectedFrequencyInDays) {
    if (!shipQuant.shipmentQuantity) return null;

    return shipQuant.shipmentQuantity + (shipQuant.shipmentUnitId && shipQuant.shipmentFrequencyId ? ' ' + productConstants.productUnitsKeyValueData[shipQuant.shipmentUnitId] + ' / ' + productConstants.productFrequenciesKeyValueData[shipQuant.shipmentFrequencyId] : '');
  }
}

export function getShipmentQuantityResultViewFromCalculatedData(
  choice: ProductConfigChoice | ProductConfigChoiceAPI,
  shipQuant: ProductsShipmentQuantity,
  productConstants: ProductConstants,
): string {
  if (!shipQuant || !productConstants) return null;

  if (shipQuant.selectedFrequencyInDays) {
    if (!shipQuant.selectedAmount) return null;

    return shipQuant.selectedAmount
      + (shipQuant.selectedUnitId && choice.isShipmentExternalProductCodeSizeShown ? ' ' + productConstants.productUnitsKeyValueData[shipQuant.selectedUnitId] : '')
      + (shipQuant.selectedFrequencyInDays && choice.isShipmentFrequencyShown ? ' / ' : '')
      + (shipQuant.selectedFrequencyInDays && choice.isShipmentFrequencyShown
        ? getFrequencySuffix(shipQuant.selectedFrequencyInDays)
        : '');
  }

  if (!shipQuant.selectedFrequencyInDays) {
    if (!shipQuant.shipmentQuantity) return null;

    return shipQuant.shipmentQuantity
      + (shipQuant.shipmentUnitId && choice.isShipmentExternalProductCodeSizeShown ? ' ' + productConstants.productUnitsKeyValueData[shipQuant.shipmentUnitId] : '')
      + (shipQuant.shipmentFrequencyId && choice.isShipmentFrequencyShown ? ' / ' : '')
      + (shipQuant.shipmentFrequencyId && choice.isShipmentFrequencyShown ? productConstants.productFrequenciesKeyValueData[shipQuant.shipmentFrequencyId] : '');
  }
}

function getFrequencySuffix(frequency: number): string {
  switch (frequency) {
    case 1: return 'Day';
    case 30: return 'Month';
    case 31: return 'Month';
    default: return frequency + ' Days';
  }
}
