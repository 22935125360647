export enum ERRORS {
  base = 'Something went wrong with your request. Please try again or contact support for more details.',
  mfa = 'Invalid or Expired Code.',
  smsError = 'We have encountered an error delivering a text message to your phone.<br><br>Please select the <strong>email</strong> option to continue.',
}

export enum ERROR_CODES {
  npiFacilityRequestTransitionNotValid = 'npi-facility-request-transition-is-not-valid',
}

export enum HTTP_ERROR_STATUSES {
  unauthorized = 401,
  connectionIssues = 0,
  notFound = 404,
}
