import { KeyStringModel } from '@models/base.models';

export class ProductConstants {
  productUnits: ProductUnit[];
  productFrequencies: ProductFrequency[];
  productUnitsKeyValueData: KeyStringModel<string>;
  productFrequenciesKeyValueData: KeyStringModel<string>;
}

export class ProductUnitAPI {
  id: number;
  name: string;
}

export class ProductUnit {
  id: number;
  name: string;

  public static getDataFromAPI(data: ProductUnitAPI): ProductUnit {
    return {
      id: data.id,
      name: data.name,
    };
  }
}

export class ProductFrequencyAPI {
  id: number;
  name: string;
}

export class ProductFrequency {
  id: number;
  name: string;

  public static getDataFromAPI(data: ProductFrequencyAPI): ProductFrequency {
    return {
      id: data.id,
      name: data.name,
    };
  }
}
