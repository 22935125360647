export const environment = {
  production: true,
  environmentName: 'training',
  url: window.location.protocol + '//' + window.location.host,

  apiUrl: 'https://qualtool-api-training.synapsehealth.com',
  azureAd: {
    clientId: '918a66c2-035e-4429-a55e-37474d61fcc9',
    authority: 'https://login.microsoftonline.com/7ef32054-2b43-4432-b465-3b165783d03a',
    redirectUri: 'https://qualtool-training.synapsehealth.com',
    scopes: ['api://a9ff1f1e-8c34-4dc1-952b-e5cdc3ac72ec/Order-ReadWrite'],
    protectedResource: 'api://a9ff1f1e-8c34-4dc1-952b-e5cdc3ac72ec/Order-ReadWrite',
  },
};
