// eslint-disable-next-line @typescript-eslint/no-unused-vars
export class KeyStringModel<T> {
  [key: string]: T;
}

export class PagedData<T> {
  count: number;
  items: T;
}

export class BaseModel<T> {
  pending: boolean;
  error: unknown;
  data: T;

  public static getNewData<M>(data: M): BaseModel<M> {
    return { ...new BaseModel(), data };
  }
}
