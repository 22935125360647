export function removeItem(item: any, array: any): void {
  const index = array.findIndex((it: any) => it.id === item.id);

  index !== -1 && array.splice(index, 1);
}

export function getArrayFromString(value: string, separator: string = ','): string[] {
  if (!value) return [];

  return value.trim().split(separator).map(it => it.trim()).filter((it: string) => it);
}

export function getParsedArrayOfArrays(array: any[]): any[] {
  if (!array || !array.length) return [];

  return [].concat.apply([], array);
}

export function getFilteredArrayByEqualFieldsValue<T>(array: T[], keys: string[]): T[] {
  // filters an array by the same field values in the array object
  if (!array || !array.length) return [];

  // @ts-ignore
  return array.filter((value, index, self) => self.findIndex(v => keys.every(k => v[k] === value[k])) === index);
}

export function getItemIndex<T, D>(array: T[], field: string, value: D): number {
  if (!array || !array.length) return null;

  // @ts-ignore
  return array.findIndex(it => it[field] === value);
}

export function getItem<T, D>(array: T[], field: string, value: D): T {
  if (!array || !array.length) return null;

  // @ts-ignore
  return array.find(it => it[field] === value);
}
