import { Subject } from 'rxjs';
import { Directive, OnDestroy } from '@angular/core';

@Directive({ selector: '[unSubscribeDirective]' })
export abstract class UnSubscribeDirective implements OnDestroy {
  protected ngUnSubscribe = new Subject<void>();

  public ngOnDestroy(): void {
    this.ngUnSubscribe.next();
    this.ngUnSubscribe.complete();
    this.ngUnSubscribe = new Subject<void>();
  }
}
