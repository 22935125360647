import { Observable } from 'rxjs';

export class ConfirmModalData {
  id = 'confirmMatModalID';
  title = 'Notification';
  text = 'Are you sure?';
  disableClose = false;
  isReverseActions = false;
  customWidth: string;

  isCancelBtn = true;
  cancelLabel = 'Cancel';
  cancelPending$: Observable<boolean>;
  cancelDisable$: Observable<boolean>;

  isDiscardBtn = false;
  discardLabel = 'Discard';
  discardPending$: Observable<boolean>;
  discardDisable$: Observable<boolean>;

  isOkBtn = true;
  isOkAction = false;
  okLabel = 'Ok';
  okPending$: Observable<boolean>;
  okDisable$: Observable<boolean>;
  okAction = (data: ConfirmModalResult): void => {};
}

export class ConfirmModalResult {
  closeType: number;
  modalData: ConfirmModalData;
}
