import { Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CONFIRM_MODAL } from '@const/modals-configs';
import { ConfirmModalData, ConfirmModalResult } from '@models/confirm-modal-data';
import { UnSubscribeDirective } from '@directives/un-subscribe/un-subscribe.directive';
import { ConfirmModalComponent } from '@components/confirm-modal/components/confirm-modal.component';

@Injectable()
export class ConfirmModalService extends UnSubscribeDirective {
  constructor(
    private readonly matDialog: MatDialog,
  ) {
    super();
  }

  public confirmModal$(data: ConfirmModalData): Observable<ConfirmModalResult> {
    const isModalOpened = this.matDialog.getDialogById(CONFIRM_MODAL.id);

    if (isModalOpened) return of(null);

    return this.matDialog
      .open(ConfirmModalComponent, {
        ...CONFIRM_MODAL,
        data,
        width: data.customWidth || CONFIRM_MODAL.width,
      })
      .afterClosed()
      .pipe(takeUntil(this.ngUnSubscribe));
  }
}
