import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ERRORS } from '@const/errors';
import { BaseRequestModel } from '@models/request';
import { getErrorMessage } from '@helpers/error.helper';
import { ConfirmModalData } from '@models/confirm-modal-data';
import { ConfirmModalService } from '@services/confirm-modal.service/confirm-modal.service';

@Injectable()
export class ErrorHandlingService {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly confirmModalService: ConfirmModalService,
  ) {}

  public handleError(response: any, withoutErrorPopup?: boolean): Observable<any> {
    if (response.status !== 401 && !withoutErrorPopup) {
      const isConfirmModalOpened = this.matDialog.openDialogs.some(dialog => dialog.componentInstance.modalData?.id === new ConfirmModalData().id);

      !isConfirmModalOpened && this.confirmModalService.confirmModal$({
        ...new ConfirmModalData(),
        text: ErrorHandlingService.getErrorMessage(response, ERRORS.base),
        isCancelBtn: false,
      }).subscribe();
    }

    throw response.status ? response : (response?.data || response);
  }

  public catchResponseError(err: BaseRequestModel, withoutErrorPopup?: boolean): any {
    if (isNil(err?.isOk)) return err;

    if (err?.isOk) {
      return err.data;
    } else {
      throw this.handleError(err, withoutErrorPopup);
    }
  }

  private static getErrorMessage(response: any, baseMessage: string): string {
    return getErrorMessage(response, baseMessage);
  }
}
