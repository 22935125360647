export enum PRODUCT_CONFIG_GROUP_TYPE {
  dropdown = 1,
  radioGroup,
  single,
}

export enum PRODUCT_CONFIG_SETTINGS_ENTRY_TYPE {
  value = 1,
  range,
}

export enum PRODUCT_CONFIG_SETTINGS_DATA_TYPE {
  integer = 1,
  decimal,
  string,
  boolean,

  // FE only
  date,
  groupDropdown = 101,
  groupRadio,
  groupCheckbox,
}

export enum PRODUCT_CONFIG_SETTINGS_GROUP_TYPE {
  dropdown = 1,
  radio,
  checkbox,
}
