import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ConfirmModalData } from '@models/confirm-modal-data';
import { CONFIRM_MODAL_CLOSE_TYPES } from '@const/confirm-modal';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent implements OnDestroy {
  public CONFIRM_MODAL_CLOSE_TYPES = CONFIRM_MODAL_CLOSE_TYPES;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ConfirmModalData = new ConfirmModalData(),
    public readonly dialogRef: MatDialogRef<ConfirmModalComponent>,
  ) {
    this.setModalClass();
    this.setBackdropClick();
  }

  public ngOnDestroy(): void {
    this.removeModalClass();
  }

  private setBackdropClick(): void {
    !this.modalData.disableClose &&
      this.dialogRef.backdropClick().subscribe(() => this.dialogRef.close({ closeType: CONFIRM_MODAL_CLOSE_TYPES.close, modalData: this.modalData }));
  }

  private setModalClass(): void {
    document.body.classList.add('_confirm-modal');
  }

  private removeModalClass(): void {
    document.body.classList.remove('_confirm-modal');
  }
}
