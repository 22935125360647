const modalWidth = 'calc(100vw - 40px)';
const modalHeight = 'calc(100vh - 40px)';

export const CONFIRM_MODAL = {
  width: '400px',
  id: 'confirmModalId',
  maxWidth: modalWidth,
  maxHeight: modalHeight,
  panelClass: 'confirm-modal',
  disableClose: true,
};
