<div class="custom-modal">
    <modal-header [title]="modalData.title"
                  (closeModal)="dialogRef.close({ closeType: CONFIRM_MODAL_CLOSE_TYPES.close, modalData })"></modal-header>
    <div class="custom-modal__content">
    <span class="confirm-modal__message _fw-500 _mb-10"
          [innerHTML]="modalData.text"></span>
        <div class="confirm-modal__actions _d-f _align-center _mt-5"
             [class._ml-auto]="!modalData.isDiscardBtn"
             [class._reverse]="modalData.isReverseActions">
            <button *ngIf="modalData.isCancelBtn"
                    class="confirm-modal__action _btn-size-s _spinner-s"
                    mat-flat-button
                    color="secondary"
                    [pending]="modalData.cancelPending$ | async"
                    [disabled]="modalData.cancelDisable$ | async"
                    [mat-dialog-close]="{ closeType: CONFIRM_MODAL_CLOSE_TYPES.cancel, modalData }">{{modalData.cancelLabel}}</button>
            <button *ngIf="modalData.isDiscardBtn"
                    class="confirm-modal__action _btn-size-s _ml-auto _spinner-s"
                    mat-flat-button
                    color="secondary"
                    [pending]="modalData.discardPending$ | async"
                    [disabled]="modalData.discardDisable$ | async"
                    [mat-dialog-close]="{ closeType: CONFIRM_MODAL_CLOSE_TYPES.discard, modalData }">{{modalData.discardLabel}}</button>
            <button *ngIf="modalData.isOkBtn"
                    class="confirm-modal__action _btn-size-s _spinner-s"
                    color="primary"
                    mat-flat-button
                    cdkFocusInitial
                    [pending]="modalData.okPending$ | async"
                    [disabled]="modalData.okDisable$ | async"
                    (keydown.enter)="modalData.isOkAction ? modalData.okAction({ closeType: CONFIRM_MODAL_CLOSE_TYPES.ok, modalData }) : dialogRef.close({ closeType: CONFIRM_MODAL_CLOSE_TYPES.ok, modalData })"
                    (click)="modalData.isOkAction ? modalData.okAction({ closeType: CONFIRM_MODAL_CLOSE_TYPES.ok, modalData }) : dialogRef.close({ closeType: CONFIRM_MODAL_CLOSE_TYPES.ok, modalData })">{{modalData.okLabel}}</button>
        </div>
    </div>
</div>
