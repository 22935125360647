import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['modal-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalHeaderComponent {
  @Input() public title: string;
  @Input() public titlePending: boolean;

  @Input() public subTitle: string;
  @Input() public isMenuButton = false;

  @Input() public isCloseBtn = true;

  @Output() public closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() public dbClickOnTitle: EventEmitter<void> = new EventEmitter<void>();
  @Output() public clickBtnCollapse: EventEmitter<void> = new EventEmitter<void>();
}
