export enum STORAGE {
  userData = 'userData',
  redirectUrl = 'redirectUrl',
  accessToken = 'accessToken',
  orderCreate = 'orderCreate',
  bodyClasses = 'bodyClasses',
  refreshToken = 'refreshToken',
  envConfigData = 'envConfigData',
  sidebarPosition = 'sidebarPosition',
  redirectUrlQuery = 'redirectUrlQuery',
  resetPasswordData = 'resetPasswordData',
  applicationInsights = 'applicationInsights',
}

export const LOGOUT_CLEAR_DATA: string[] = [
  STORAGE.userData,
  STORAGE.accessToken,
  STORAGE.refreshToken,
];
