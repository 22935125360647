import { ProductConfigChoiceAPI } from '@models/product';
import { PRODUCT_CONFIG_SETTINGS_DATA_TYPE, PRODUCT_CONFIG_SETTINGS_GROUP_TYPE } from '@const/product-config';

export function getChoiceSettingsDataType(setting: ProductConfigChoiceAPI): number {
  if (!setting) return null;

  if (setting.settingsGroupType) {
    switch (setting.settingsGroupType) {
      case PRODUCT_CONFIG_SETTINGS_GROUP_TYPE.dropdown:
        return PRODUCT_CONFIG_SETTINGS_DATA_TYPE.groupDropdown;
      case PRODUCT_CONFIG_SETTINGS_GROUP_TYPE.radio:
        return PRODUCT_CONFIG_SETTINGS_DATA_TYPE.groupRadio;
      case PRODUCT_CONFIG_SETTINGS_GROUP_TYPE.checkbox:
        return PRODUCT_CONFIG_SETTINGS_DATA_TYPE.groupCheckbox;
    }
  }

  return setting.dataType;
}
