import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, finalize, forkJoin, Observable, of } from 'rxjs';

import * as Models from '@models/constants.models';

import { BaseModel } from '@models/base.models';
import { BaseRequestModel } from '@models/request';
import { getKeyStringData } from '@helpers/object.helper';
import { UrlService } from '@services/url.service/url.service';
import { RequestService } from '@services/request.service/request.service';
import { ErrorHandlingService } from '@services/error-handling.service/error-handling.service';

@Injectable()
export class ConstantsService {
  public productConstants$ = new BehaviorSubject<BaseModel<Models.ProductConstants>>(BaseModel.getNewData(new Models.ProductConstants()));

  constructor(
    private readonly urlService: UrlService,
    private readonly requestService: RequestService,
    private readonly errorHandlingService: ErrorHandlingService,
  ) {}

  public getProductConstants(): void {
    this.getProductConstants$().subscribe();
  }

  private getProductConstants$(): Observable<BaseModel<Models.ProductConstants>> {
    this.setProductConstantsPending(true);

    return forkJoin([this.getProductsUnits$(), this.getProductsFrequencies$()])
      .pipe(
        finalize(() => this.setProductConstantsPending(false)),
        map(() => this.productConstants$.value),
        catchError(() => this.productConstants$),
      );
  }

  private getProductsUnits$(): Observable<Models.ProductUnit[]> {
    return this.requestService
      .get(this.urlService.getProductsUnitsURL())
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: Models.ProductUnitAPI[]) => data.map(it => Models.ProductUnit.getDataFromAPI(it))),
        tap((productUnits: Models.ProductUnit[]) => this.setProductConstantsProductUnits(productUnits)),
        catchError(() => {
          this.setProductConstantsProductUnits([]);

          return of([]);
        }));
  }

  private getProductsFrequencies$(): Observable<Models.ProductFrequency[]> {
    return this.requestService
      .get(this.urlService.getProductsFrequenciesURL())
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: Models.ProductFrequencyAPI[]) => data.map(it => Models.ProductFrequency.getDataFromAPI(it))),
        tap((productFrequencies: Models.ProductFrequency[]) => this.setProductConstantsProductFrequencies(productFrequencies)),
        catchError(() => {
          this.setProductConstantsProductFrequencies([]);

          return of([]);
        }));
  }

  private setProductConstantsPending(pending: boolean): void {
    this.productConstants$.next({ ...this.productConstants$.value, pending });
  }

  private setProductConstantsProductUnits(productUnits: Models.ProductUnit[]): void {
    this.productConstants$.next({
      ...this.productConstants$.value,
      data: { ...this.productConstants$.value.data, productUnits, productUnitsKeyValueData: getKeyStringData(productUnits, 'id', 'name') },
    });
  }

  private setProductConstantsProductFrequencies(productFrequencies: Models.ProductFrequency[]): void {
    this.productConstants$.next({
      ...this.productConstants$.value,
      data: { ...this.productConstants$.value.data, productFrequencies, productFrequenciesKeyValueData: getKeyStringData(productFrequencies, 'id', 'name') },
    });
  }

}
