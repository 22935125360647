import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseRequestModel } from '@models/request';
import { ProductConstants } from '@models/constants.models';
import { UrlService } from '@services/url.service/url.service';
import { RequestService } from '@services/request.service/request.service';
import { ErrorHandlingService } from '@services/error-handling.service/error-handling.service';
import { ProductConfigGroup, ProductConfigGroupAPI, ProductsShipmentQuantity, ProductsShipmentQuantityAPI, ProductsShipmentQuantityRequest } from '@models/product';

@Injectable()
export class ProductsService {
  constructor(
    private readonly urlService: UrlService,
    private readonly requestService: RequestService,
    private readonly errorHandlingService: ErrorHandlingService,
  ) {}

  public getProductConfigs$(productConstants: ProductConstants): Observable<ProductConfigGroup[]> {
    const forkJoinData = this.PRODUCTS;

    return forkJoin(forkJoinData)
      .pipe(
        map((data: BaseRequestModel[]) => ({ data: data.map(it => it.data), isOk: true })),
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: ProductConfigGroupAPI[]) => data.map(it => ProductConfigGroup.getDataFromAPI(it, productConstants))),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }

  public getProductsShipmentQuantity$(products: ProductsShipmentQuantityRequest[]): Observable<ProductsShipmentQuantity[]> {
    return this.requestService
      .put(this.urlService.getProductsShipmentQuantityURL(), { products })
      .pipe(
        map((data: BaseRequestModel) => this.errorHandlingService.catchResponseError(data)),
        map((data: ProductsShipmentQuantityAPI[]) => data.map(it => ProductsShipmentQuantity.getDataFromAPI(it))),
        catchError((err) => this.errorHandlingService.handleError(err)));
  }

  private readonly PRODUCTS: Observable<BaseRequestModel>[] = [
    this.requestService.get('/products/Oxygen.json'),
    this.requestService.get('/products/Non-Invasive_Ventilation.json'),
    this.requestService.get('/products/Negative_Pressure_Wound_Therapy.json'),
    this.requestService.get('/products/Invasive_Ventilation.json'),
    this.requestService.get('/products/Generic-CPAP_Device_and_Supplies.json'),
    this.requestService.get('/products/Generic-Bi-Level_With_Back_Up_Rate.json'),
    this.requestService.get('/products/Generic-Bi-level_Device_and_Supplies.json'),
    this.requestService.get('/products/Enteral_Nutrition_Via_Feeding_Pump.json'),
  ];
}
