<div class="modal-header _d-f _justify-between _align-center _pl-20 _pr-20 _pt-10 _pb-10">
  <span class="modal-header__title _fw-600 _spinner-xs"
        [pending]="titlePending"
        [innerHTML]="title"
        (dblclick)="dbClickOnTitle.emit()"></span>
  <button *ngIf="isCloseBtn"
          class="modal-header__close-btn"
          mat-icon-button
          type="button"
          tabindex="-1"
          (click)="closeModal.emit()">
    <mat-icon class="modal-header__close-btn-icon"
              svgIcon="close"></mat-icon></button>
</div>
