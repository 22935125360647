import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RequestService {
  constructor(
    private readonly http: HttpClient,
  ) {}

  public get(url: string, options?: {}): any {
    return options ? this.http.get(url, options) : this.http.get(url);
  }

  public delete(url: string, options?: {}): any {
    return options ? this.http.delete(url, options) : this.http.delete(url);
  }

  public post(url: string, body: {} = {}, options?: {}): any {
    return this.http.post(url, body, options);
  }

  public put(url: string, body: {} = {}, options?: {}): any {
    return this.http.put(url, body, options);
  }
}
