export class HcpcsCodeAPI {
  code: string;
  description: string;
  shortDescription: string;
}

export class HcpcsCode {
  code: string;
  description: string;
  shortDescription: string;

  public static getDataFromAPI(data: HcpcsCodeAPI): HcpcsCode {
    return {
      code: data.code,
      description: data.description,
      shortDescription: data.shortDescription,
    };
  }
}
