// noinspection JSUnusedGlobalSymbols

import { KeyStringModel } from '@models/base.models';
import { cloneDeep, isEqualWith, isNil, isNull } from 'lodash';

export function isEqualObjects(obj1: any, obj2: any): boolean {
  return isEqualWith(obj1, obj2, (a, b) => {
    if ((isNull(a) || a === '') && (isNull(b) || b === '')) return true;
  });
}

export function isJsonString(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}

export function getDataWithoutEmptyFields<T>(data: T): T {
  const preparedData = cloneDeep(data);

  for (const fieldName in preparedData) isNil(preparedData[fieldName]) && delete preparedData[fieldName];

  return preparedData;
}

export function getConvertedEmptyFieldsToNull<T>(data: T): T {
  if (!data) return null;

  const fixedData: T = cloneDeep(data);

  for (const key in fixedData) {
    if (fixedData.hasOwnProperty(key)) {
      if (typeof fixedData[key] === 'object' && fixedData[key] !== null) {
        getConvertedEmptyFieldsToNull(fixedData[key]);
        // @ts-ignore
      } else if (fixedData[key] === '' || fixedData[key] === undefined) {
        fixedData[key] = null;
      }
    }
  }

  return fixedData;
}

export function getKeyStringData<T, R>(data: T[], key: string, value: R): KeyStringModel<R> {
  if (!data || !data.length) return new KeyStringModel();

  const keyStringData: KeyStringModel<R> = new KeyStringModel();

  data.forEach((it: T | any) => keyStringData[it[key]] = it[value]);

  return keyStringData;
}

export function isObjectFullyFilled<T>(obj: T): boolean {
  // @ts-ignore
  return Object.keys(obj).every((key: string) => obj[key] !== undefined && obj[key] !== null && obj[key] !== '');

}
