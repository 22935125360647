import { NgForm, NgModel, Validators } from '@angular/forms';

import { KeyStringModel } from '@models/base.models';
import { CheckboxesForm } from '@models/choice-form';
import { CHECKBOXES_FORM_VALIDATION_TYPE } from '@const/forms-contants';

export function triggerNgModelValidation(ngModel: NgModel): void {
  ngModel.control.markAsTouched();
  ngModel.control.updateValueAndValidity();
}

export function isFormValid(form: NgForm, checkboxesForm?: CheckboxesForm): boolean {
  if (!form) return;

  if (checkboxesForm) {
    switch (checkboxesForm.requiredType) {
      case CHECKBOXES_FORM_VALIDATION_TYPE.selectAtLeastOne:
        const atLeastOneSelected: boolean = form && Object.values(form.value).some(it => it);

        atLeastOneSelected
          ? form.control.removeValidators(Validators.requiredTrue)
          : form.control.setValidators(Validators.requiredTrue);

        break;
      case CHECKBOXES_FORM_VALIDATION_TYPE.selectAll:
        const allSelected: boolean = form && !Object.values(form.value).some(it => !it);

        allSelected
          ? form.control.removeValidators(Validators.requiredTrue)
          : form.control.setValidators(Validators.requiredTrue);

        break;
    }
  }

  for (const key in form.controls) {
    form.controls[key].markAsTouched();
    form.controls[key].updateValueAndValidity();
  }

  form.control.markAsTouched();
  form.control.updateValueAndValidity();

  return form.valid;
}

export function isFormFieldsValid(form: NgForm, formFields: string[]): boolean {
  if (!formFields?.length) return;

  formFields.forEach(it => {
    form.controls[it].markAsTouched();
    form.controls[it].updateValueAndValidity();
  });

  return formFields.every(it => form.controls[it].valid);
}

export function isSomeFieldsFilled(form: NgForm): boolean {
  if (!form) return true;

  const data = [];

  for (const key in form.value) {
    data.push(form.value[key]);
  }

  return data.some(it => it);
}

export function getPreparedNgFormDataFromAnyData(form: NgForm, data: any): any {
  const formData: KeyStringModel<any> = {};

  for (const key in form.value) {
    formData[key] = data?.[key] ?? form.value?.[key] ?? null; // todo: check do we need form.value?.[key](copy previous value field form if no new value)
  }

  return formData;
}
