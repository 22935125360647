import { tap } from 'rxjs/operators';
import { BehaviorSubject, MonoTypeOperatorFunction, Subscription } from 'rxjs';
import { Directive, ElementRef, Host, Input, OnDestroy, OnInit, Renderer2, RendererFactory2 } from '@angular/core';

@Directive({ selector: '[pending]' })

export class LoadingDirective implements OnInit, OnDestroy {
  private loading$ = new BehaviorSubject<boolean>(false);

  private subscription = new Subscription();
  private overlay: HTMLDivElement;

  private spinnerSrc = 'assets/icons/short-logo-colored.svg';
  private renderer: Renderer2;
  private elPosition: string;

  @Input() set pending(loading: boolean) { this.loading$.next(loading); }

  constructor(
    @Host() private elRef: ElementRef<HTMLElement>,
    private readonly rendererFactory: RendererFactory2,
  ) {}

  public ngOnInit(): void {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.overlay = this.createOverlay(this.elRef.nativeElement);
    this.subscription = this.loading$.pipe(this.toggleOp(this.overlay)).subscribe();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public createOverlay(el: HTMLElement): HTMLDivElement {
    this.elPosition !== 'absolute' && this.renderer.setStyle(el, 'position', 'relative');

    const baseSpinner: HTMLDivElement = this.renderer.createElement('div');
    const baseSpinnerWrap: HTMLDivElement = this.renderer.createElement('div');

    this.renderer.addClass(baseSpinner, 'base-spinner');
    this.renderer.addClass(baseSpinnerWrap, 'base-spinner__wrapper');
    this.renderer.appendChild(baseSpinnerWrap, baseSpinner);
    this.renderer.appendChild(el, baseSpinnerWrap);
    this.renderer.setStyle(baseSpinnerWrap, 'display', 'none');

    return baseSpinnerWrap;
  }

  public toggleOp(overlay: HTMLDivElement): MonoTypeOperatorFunction<boolean> {
    return tap(loading => {
      loading
        ? this.renderer.setStyle(overlay, 'display', '')
        : this.renderer.setStyle(overlay, 'display', 'none');
    });
  }
}
