import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable, from } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment'; // Import environment

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string | null = null;

  constructor(private msalService: MsalService) {}

  // Method to get the token and store it locally
  getToken(forceRefresh: boolean = false): Observable<string> {
    if (this.token && !forceRefresh) {
      return from(Promise.resolve(this.token));
    }

    return from(this.msalService.acquireTokenSilent({
      scopes: environment.azureAd.scopes, // Use environment variable for scopes
      account: this.msalService.instance.getAllAccounts()[0],
      forceRefresh: forceRefresh,
    })).pipe(
      map((result: AuthenticationResult) => {
        this.token = result.accessToken; // Store the token in the service
        localStorage.setItem('authToken', this.token); // Store the token in local storage
        console.log('Token acquired silently:', this.token); // Log the token

        return result.accessToken;
      }),
      catchError(error => {
        console.error('Silent token acquisition failed, falling back to interactive login', error);

        return from(this.msalService.acquireTokenPopup({
          scopes: environment.azureAd.scopes, // Use environment variable for scopes
        })).pipe(
          map((result: AuthenticationResult) => {
            this.token = result.accessToken; // Store the token in the service
            localStorage.setItem('authToken', this.token); // Store the token in local storage
            console.log('Token acquired via popup:', this.token); // Log the token

            return result.accessToken;
          }),
        );
      }),
    );
  }
}
