import { Injectable } from '@angular/core';

import { environment } from '@env/environment';

@Injectable()
export class UrlService {

  public getProductsShipmentQuantityURL(): string {
    return `${environment.apiUrl + '/ConnectApi/calculate-shipment-quantity'}`;
  }

  public getProductsUnitsURL(): string {
    return `${environment.apiUrl + '/ConnectApi/get-all-units'}`;
  }

  public getProductsFrequenciesURL(): string {
    return `${environment.apiUrl + '/ConnectApi/get-all-frequencies'}`;
  }
}
