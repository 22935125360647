import { Injectable } from '@angular/core';

import { ChoiceForm } from '@models/choice-form';
import { isFormValid } from '@helpers/form.helper';

@Injectable({ providedIn: 'root' })
export class ProductConfigFormService {
  public choicesForms: ChoiceForm[] = [];

  public addForm(form: ChoiceForm): void {
    this.choicesForms.push(form);
  }

  public removeForm(id: string): void {
    const index = this.choicesForms.findIndex(it => it.id === id);

    index !== -1 && this.choicesForms.splice(index, 1);
  }

  public isFormsValid(): boolean {
    this.choicesForms.forEach(it => isFormValid(it.form, it.checkboxesForm));

    return this.choicesForms.every(it => isFormValid(it.form, it.checkboxesForm));
  }
}
